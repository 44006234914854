import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { css } from '@emotion/core'
import tw, { styled } from 'twin.macro'

import { StyledButton } from '../button'

export const StyledLabel = styled.label`
  ${tw`block font-heading mb-1 text-sm`}
`

export const StyledError = styled.div`
  ${tw`text-brand-red mt-1 text-xs`}
`

export const StyledMessage = styled.div`
  ${tw`text-brand-red mt-1 text-xs`}
`

export const StyledInput = styled.input`
  ${tw`appearance-none block border border-transparent w-full text-black px-2 py-2  bg-brand-light-grey text-base font-body rounded-none`}
`

export const StyledTextarea = styled.textarea`
  ${tw`appearance-none block border border-transparent w-full text-black px-2 py-2  bg-brand-light-grey text-base font-body rounded-none`}
`

export const StyledSelectWrapper = styled.label`
  ${tw`relative block w-full border border-transparent`}

  &::after {
    ${tw`absolute right-0 top-0 h-full block pointer-events-none z-20`}
    content: ' ';
    width: 40px;
    background: url('/images/dropdown-arrow.svg') no-repeat center center;
    background-size: 20px 20px;
  }

  select {
    ${tw`appearance-none block w-full text-black px-2 py-2  bg-brand-light-grey text-base font-body rounded-none`}
  }
`

export const StyledCheckboxTextLabel = styled.label`
  ${tw`inline-block align-middle text-xs md:text-sm relative text-left`};
  padding-left: 37px;
  user-select: none;
  height: 22px;
  line-height: 22px !important;
`

export const StyledCheckboxStyleLabel = styled.label`
  ${tw`inline-block relative rounded-sm border-2 border-white`};
  height: 22px;
  margin-right: 2px;
  width: 22px;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled
      ? tw`bg-brand-light-grey border-brand-light-grey`
      : tw`bg-black border-brand-dark-grey`}

  &::before,
  &::after {
    ${tw`absolute bg-black inline-block h-0`};
    content: '';
    width: 2px;
    box-sizing: border-box;
    transform-origin: left top;

    ${({ disabled }) => (disabled ? tw`bg-black` : tw`bg-white`)}
  }

  &::before {
    top: 15px;
    left: 7px;
    transform: rotate(-135deg);
  }

  &::after {
    top: 10px;
    left: 2px;
    transform: rotate(-45deg);
  }
`

export const StyledCheckbox = styled.input`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);

  & + ${StyledCheckboxTextLabel}::before {
    content: '';
    ${tw`inline-block relative rounded-sm border-2 border-white absolute left-0`};
    height: 22px;
    margin-right: 2px;
    width: 22px;
    box-sizing: border-box;

    ${({ disabled }) =>
      disabled
        ? tw`bg-brand-light-grey border-brand-light-grey`
        : tw`bg-black border-brand-dark-grey`}
  }

  &:focus + ${StyledCheckboxTextLabel}::before {
    ${tw`outline-none bg-gray-700`}
  }

  &:checked + ${StyledCheckboxTextLabel}::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 5px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    height: 7px;
    width: 13px;
    transform: rotate(-45deg);

    ${({ disabled }) =>
      disabled
        ? 'border-left: 2px solid black; border-bottom: 2px solid black;'
        : 'border-left: 2px solid white; border-bottom: 2px solid white;'}
`

export const FormLayout = styled.div`
  ${tw`flex flex-wrap -mx-4`}
`

export const FormColumn = styled.div`
  ${tw`w-full px-4 mb-4 last:mb-0 flex justify-center`}
  ${({ width }) => width === '1/2' && tw`w-1/2`}
  ${({ width }) => width === '1/3' && tw`w-1/3`}
  ${({ width }) => width === '1/4' && tw`w-1/4`}
  ${({ width }) => width === '1/5' && tw`w-1/5`}
  ${({ width }) => width === '1/6' && tw`w-1/6`}
  ${({ mdWidth }) => mdWidth === '1/2' && tw`md:w-1/2`}
  ${({ mdWidth }) => mdWidth === '1/3' && tw`md:w-1/3`}
  ${({ mdWidth }) => mdWidth === '1/4' && tw`md:w-1/4`}
  ${({ mdWidth }) => mdWidth === '1/5' && tw`md:w-1/5`}
  ${({ mdWidth }) => mdWidth === '1/6' && tw`md:w-1/6`}
  ${({ lgWidth }) => lgWidth === '1/2' && tw`lg:w-1/2`}
  ${({ lgWidth }) => lgWidth === '1/3' && tw`lg:w-1/3`}
  ${({ lgWidth }) => lgWidth === '1/4' && tw`lg:w-1/4`}
  ${({ lgWidth }) => lgWidth === '1/5' && tw`lg:w-1/5`}
  ${({ lgWidth }) => lgWidth === '1/6' && tw`lg:w-1/6`}
  ${({ xlWidth }) => xlWidth === '1/2' && tw`xl:w-1/2`}
  ${({ xlWidth }) => xlWidth === '1/3' && tw`xl:w-1/3`}
  ${({ xlWidth }) => xlWidth === '1/4' && tw`xl:w-1/4`}
  ${({ xlWidth }) => xlWidth === '1/5' && tw`xl:w-1/5`}
  ${({ xlWidth }) => xlWidth === '1/6' && tw`xl:w-1/6`}
`

export const TextInput = ({ label, required, innerRef, ...props }) => {
  const [field, meta] = useField(props)

  return (
    <>
      {label && (
        <StyledLabel htmlFor={props.id || props.name}>
          {label} {required && <span tw="text-brand-red"> *</span>}
        </StyledLabel>
      )}
      <StyledInput
        css={
          meta.touched &&
          meta.error &&
          css`
            ${tw`border-brand-red`}
          `
        }
        required={required ? 'required' : null}
        ref={innerRef ? innerRef : null}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  innerRef: PropTypes.string,
}

TextInput.defaultProps = {
  label: null,
  required: false,
  innerRef: null,
}

export const TextareaInput = ({ label, required, innerRef, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      {label && (
        <StyledLabel htmlFor={props.id || props.name}>
          {label} {required && <span tw="text-brand-red"> *</span>}
        </StyledLabel>
      )}
      <StyledTextarea
        css={
          meta.touched &&
          meta.error &&
          css`
            ${tw`border-brand-red`}
          `
        }
        required={required ? 'required' : null}
        ref={innerRef || null}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </>
  )
}

TextareaInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  innerRef: PropTypes.string,
}

TextareaInput.defaultProps = {
  label: null,
  required: false,
  innerRef: null,
}

export const SelectDropdown = ({ label, required, innerRef, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <>
      {label && (
        <StyledLabel htmlFor={props.id || props.name}>
          {label} {required && <span tw="text-brand-red"> *</span>}
        </StyledLabel>
      )}

      <StyledSelectWrapper
        htmlFor={props.id || props.name}
        css={
          meta.touched &&
          meta.error &&
          css`
            ${tw`border-brand-red`}
          `
        }
      >
        <select
          required={required ? 'required' : null}
          ref={innerRef || null}
          {...field}
          {...props}
        />
      </StyledSelectWrapper>

      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </>
  )
}

SelectDropdown.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  innerRef: PropTypes.string,
}

SelectDropdown.defaultProps = {
  label: null,
  required: false,
  innerRef: null,
}

export const Checkbox = ({ label, required, innerRef, children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  return (
    <>
      {label && (
        <StyledLabel htmlFor={props.id || props.name}>
          {label} {required && <span tw="text-brand-red"> *</span>}
        </StyledLabel>
      )}

      <div tw="relative flex items-center">
        <StyledCheckbox
          type="checkbox"
          id={props.id || props.name}
          ref={innerRef || null}
          disabled={props.disabled}
          {...field}
          {...props}
        />
        <StyledCheckboxTextLabel htmlFor={props.id || props.name}>
          {children}
        </StyledCheckboxTextLabel>
      </div>

      {meta.touched && meta.error ? (
        <StyledError>{meta.error}</StyledError>
      ) : null}
    </>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  innerRef: PropTypes.string,
  children: PropTypes.node,
}

Checkbox.defaultProps = {
  label: null,
  required: false,
  innerRef: null,
  children: null,
}

export const SubmitButton = ({
  isSubmitting,
  text,
  submittingText,
  ...props
}) => (
  <StyledButton
    type="submit"
    disabled={isSubmitting ? 'disabled' : null}
    css={
      isSubmitting &&
      css`
        ${tw`bg-white border-black text-black cursor-wait hover:border-black hover:bg-white`}
      `
    }
    {...props}
  >
    {isSubmitting ? submittingText || 'Please wait...' : text || 'Submit'}
  </StyledButton>
)

SubmitButton.propTypes = {
  text: PropTypes.string,
  isSubmitting: PropTypes.bool,
  submittingText: PropTypes.string,
}

SubmitButton.defaultProps = {
  text: null,
  isSubmitting: false,
  submittingText: null,
}

export const StatusMessage = ({ isError, children }) =>
  children && (
    <div
      tw="mb-4"
      css={
        isError &&
        css`
          ${tw`text-brand-red`}
        `
      }
    >
      {children}
    </div>
  )

TextInput.propTypes = {
  isError: PropTypes.bool,
  children: PropTypes.node,
}

TextInput.defaultProps = {
  isError: false,
  label: null,
}

export const requiredMessageErrorText = 'This field is required.'
export const emailInvalidErrorText = 'Invalid email address.'
